import api from '../../utils/api';

export const getUserNotifications = async () => {
  return api.get(`/notifications`);
};

export const markAsRead = async (notification) => {
  return api.post(`/notifications/mark-as-read/${notification}`);
};

export const getAllNotifications = async () => {
  return api.get(`/notifications/all`);
};

export const createNotification = async (data) => {
  return api.post('/notifications/create', data);
};